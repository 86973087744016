import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { useCurrentUser } from '../hooks/useCurrentUser';

const Initials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #3c6a91;
  border-radius: 50%;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  width: 40px;
  height: 40px;
`;

export const NavDropdownTitle = () => {
  const currentUser = useCurrentUser();
  const title = currentUser ? (
    `${currentUser?.name[0]}${
      (currentUser?.surname && currentUser.surname[0]) || ''
    }`
  ) : (
    <Spinner
      as="span"
      aria-hidden="true"
      animation="border"
      role="status"
      size="sm"
    />
  );
  return <Initials>{title}</Initials>;
};
