export const sortByName = (
  obj1: { name: string },
  obj2: { name: string }
): number => {
  if (obj1.name.toLowerCase() < obj2.name.toLowerCase()) {
    return -1;
  } else if (obj1.name.toLowerCase() > obj2.name.toLowerCase()) {
    return 1;
  } else {
    return 0;
  }
};

export const sortByExtId = (
  obj1: { extId?: string },
  obj2: { extId?: string }
): number => {
  if (obj1.extId.length < obj2.extId.length) {
    return -1;
  } else if (obj1.extId.length > obj2.extId.length) {
    return 1;
  } else {
    if (obj1.extId.toLowerCase() < obj2.extId.toLowerCase()) {
      return -1;
    }
    if (obj1.extId.toLowerCase() > obj2.extId.toLowerCase()) {
      return 1;
    }
    return 0;
  }
};
