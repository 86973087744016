import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';
import { AccessTokenPayload } from '@portal/nest/auth-module';
import { useGlobalConfig } from '../components/GlobalConfigProvider';
import { AccessToken } from '@portal/frontend/utils';

function parseJwt<T = Record<string, any>>(token: string): T {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    return undefined;
  }
}

type UseAccessToken = () => {
  cookieName: AccessToken;
  accessToken: string | undefined;
  decodedToken?: AccessTokenPayload | undefined;
  setCookie: ReturnType<typeof useCookies>[1];
  removeCookie: () => void;
};

export const useAccessToken: UseAccessToken = () => {
  const { accessTokenKeyName } = useGlobalConfig();
  const cookieName = accessTokenKeyName;
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const accessToken = cookies[cookieName];
  const decodedToken = parseJwt<AccessTokenPayload>(accessToken);
  return {
    cookieName,
    accessToken,
    decodedToken: decodedToken,
    setCookie: (value: any, options: CookieSetOptions) => {
      setCookie(cookieName, value, options);
    },
    removeCookie: () => {
      removeCookie(cookieName);
    },
  };
};
