enum Endpoint {
  LOGIN = '/auth/login',
  EMPLOYEES = '/employees',
  MONTHLY_WORKING_HOURS = '/monthlyWorkingHours',
  DAILY_WORK_TIMESPANS = '/dailyWorkTimespans',
  PROJECTS_EMPLOYEE = '/projects/employee',
  TIMETABLE_ENTRIES_UPDATE = '/timetableEntries/update',
  CHANGE_WORK_HOUR = `/timetableEntries/reassign`,
  WORKITEM_ROLE_HOURS = '/workItemRoleHours',
  TIMETABLE_ENTRIES = '/timetableEntries',
  EMPLOYEE_WORK_HOURS = '/employeeWorkHours',
  TOTAL_WORK_HOURS = '/totalWorkHours',
  EMPLOYEE_LOCK_TIME = '/lockTime/employeeLockTime',
}

export default Endpoint;
