import { useState } from 'react';
import { Moment } from 'moment-business-days';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { getDaysFromSearchParams } from '../functions/getDaysFromSearchParams';

export function useDaysOfWeek(): [
  Moment[],
  React.Dispatch<React.SetStateAction<Moment[]>>,
] {
  const [searchParams] = useSearchParams();

  const startDate = moment(searchParams.get('startDate'), 'YYYY-MM-DD');
  const endDate = moment(searchParams.get('endDate'), 'YYYY-MM-DD');
  const isStartDateValid = moment(
    searchParams.get('startDate'),
    'YYYY-MM-DD',
    true,
  ).isValid();
  const isEndDateValid = moment(
    searchParams.get('endDate'),
    'YYYY-MM-DD',
    true,
  ).isValid();

  let weekStart;

  if (isStartDateValid) {
    weekStart = startDate;
  } else if (!isStartDateValid && isEndDateValid) {
    weekStart = endDate.subtract(6, 'days');
  } else {
    weekStart = moment().startOf('isoWeek');
  }

  const [daysOfWeek, setDaysOfWeek] = useState<Moment[]>(
    getDaysFromSearchParams(weekStart),
  );

  return [daysOfWeek, setDaysOfWeek];
}
