import React, { FC, useEffect } from 'react';
import { ReportsFiltersDto } from '@portal/developer-portal-api-model';
import {
  customTimeRangeOption,
  defaultTimeRangeOption,
  getFirstDayOfMonth,
  getFirstDayOfWeek,
  getFirstDayOfYear,
  getLastDayOfMonth,
  getLastDayOfWeek,
  getLastDayOfYear,
  SelectOption,
  TimeRange,
  timeSelectRangeOptions,
} from '@portal/frontend/utils';
import { useFormik } from 'formik';
import moment from 'moment';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

interface ReportsFormProps {
  onSubmitHandler: (values: ReportsFiltersDto) => void;
}

const initialTouched = {
  startDate: false,
  finishDate: false,
  timeRange: false,
};

const ReportsForm: FC<ReportsFormProps> = ({ onSubmitHandler }) => {
  const {
    handleChange,
    handleSubmit,
    values,
    setFieldValue,
    touched,
    setTouched,
  } = useFormik<
    ReportsFiltersDto & {
      timeRangeOption: SelectOption;
    }
  >({
    initialValues: {
      finishDate: moment(getLastDayOfMonth(moment().toDate())).format(
        'YYYY-MM-DD',
      ),
      startDate: moment(getFirstDayOfMonth(moment().toDate())).format(
        'YYYY-MM-DD',
      ),
      timeRangeOption: defaultTimeRangeOption,
    },
    initialTouched: initialTouched,
    onSubmit: (values) => {
      onSubmitHandler({
        startDate: values.startDate,
        finishDate: values.finishDate,
      });
    },
  });

  useEffect(() => {
    if (touched.startDate || touched.finishDate) {
      setTouched(initialTouched);
    }
  }, [setFieldValue, touched.startDate, touched.finishDate, setTouched]);

  const updateDates = (timeRange: TimeRange) => {
    const NOW = new Date();

    switch (timeRange) {
      case TimeRange.THIS_WEEK:
        setFieldValue(
          'startDate',
          moment(getFirstDayOfWeek(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(getLastDayOfWeek(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.LAST_WEEK:
        setFieldValue(
          'startDate',
          moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(
            getLastDayOfWeek(
              new Date(NOW.setDate(NOW.getDate() - 7)),
            ).toISOString(),
          ).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.THIS_MONTH:
        setFieldValue(
          'startDate',
          moment(getFirstDayOfMonth(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(getLastDayOfMonth(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.LAST_MONTH:
        setFieldValue(
          'startDate',
          moment(
            getFirstDayOfMonth(
              new Date(NOW.getFullYear(), NOW.getMonth() - 1, 1),
            ).toISOString(),
          ).format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(
            getLastDayOfMonth(
              new Date(NOW.getFullYear(), NOW.getMonth() - 1, 1),
            ).toISOString(),
          ).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.THIS_YEAR:
        setFieldValue(
          'startDate',
          moment(getFirstDayOfYear(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(getLastDayOfYear(NOW).toISOString()).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.LAST_YEAR:
        setFieldValue(
          'startDate',
          moment(
            getFirstDayOfYear(
              new Date(NOW.getFullYear() - 1, 0, 1),
            ).toISOString(),
          ).format('YYYY-MM-DD'),
        );
        setFieldValue(
          'finishDate',
          moment(
            getLastDayOfYear(
              new Date(NOW.getFullYear() - 1, 0, 1),
            ).toISOString(),
          ).format('YYYY-MM-DD'),
        );
        break;
      case TimeRange.CUSTOM:
        setFieldValue('startDate', values.startDate);
        setFieldValue('finishDate', values.finishDate);
        break;
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="w-100">
      <Col>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Select Time range</Form.Label>
              <Select<SelectOption, false>
                closeMenuOnSelect={true}
                options={timeSelectRangeOptions}
                value={values.timeRangeOption}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                onChange={(selectedOption) => {
                  setFieldValue('timeRangeOption', selectedOption);
                  updateDates(selectedOption.value as TimeRange);
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    minWidth: 150,
                  }),
                }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                type="date"
                required
                name={'startDate'}
                value={values.startDate}
                onChange={(...args) => {
                  setFieldValue('timeRangeOption', customTimeRangeOption);
                  handleChange(...args);
                  setTouched({
                    ...initialTouched,
                    startDate: true,
                  });
                }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>&nbsp;</Form.Label>
              <Row>
                <Form.Control
                  type="date"
                  required
                  name={'finishDate'}
                  value={values.finishDate}
                  onChange={(...args) => {
                    setFieldValue('timeRangeOption', customTimeRangeOption);
                    handleChange(...args);
                  }}
                />
              </Row>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>&nbsp;</Form.Label>
              <Button
                style={{ width: '100%', marginBottom: '10px' }}
                type="submit"
                variant="primary"
              >
                Show results
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default ReportsForm;
