import {
  CellChange,
  HeaderCell,
  NumberCell,
  ReactGrid,
  Row as RowType,
  TextCell,
} from '@silevis/reactgrid';
import {
  UpsertDailyWorkTimespanDto,
  UpsertDailyWorkTimespansDto,
} from 'libs/developer-portal-api-model/src/lib/dto/dailyWorkTimespan.dto';
import {
  UpsertTimetableEntriesDto,
  UpsertTimetableEntriesResponseDto,
  UpsertTimetableEntryDto,
} from 'libs/developer-portal-api-model/src/lib/dto/timetableEntry.dto';
import { CreateWorkItemRoleHoursDto } from 'libs/developer-portal-api-model/src/lib/dto/WorkItemRoleHours.dto';
import { sendRequest } from 'libs/frontend/utils/src/lib/sendRequest';
import moment, { Moment } from 'moment';
import React, { useMemo, useState } from 'react';
import { Badge, Button, ButtonGroup, Form, Row } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import '../assets/scss/components/timesheet.scss';
import Endpoint from '../enums/apiEndpoints';
import { getTimeSheetTableColumns } from '../functions/getTimeSheetTableColumns';
import { getTotalRows } from '../functions/getTotalRows';
import { getWorkingTimeRows } from '../functions/getWorkingTimeRows';
import { getWorkItemRows } from '../functions/getWorkItemRows';
import { makeCellsNonEditable } from '../functions/makeCellsNonEditable';
import { rejectArchivedProjectsWithoutLoggedTimeFilter } from '../functions/rejectArchivedProjectsWithoutLoggedTimeFilter';
import { useDailyWorkTimespans } from '../hooks/useDailyWorkTimespans';
import { useEmployeeProjects } from '../hooks/useEmployeeProjects';
import { useHiddenProjects } from '../hooks/useHiddenProjects';
import { RowCell, WorkHoursCell } from '../interfaces/reactgrid.interface';
import {
  ChangeWeekCell,
  ChangeWeekCellTemplate,
} from '../templates/ChangeWeekCell';
import { ExtendedHeaderCellTemplate } from '../templates/ExtendedHeaderCellTemplate';
import { NonEditableChevronCellTemplate } from '../templates/NonEditableChevronCell';
import { Loader } from './Loader';
import { LoadingOverlay } from './LoadingOverlay';
import { WorkItemRoleHours } from '../interfaces/WorkItemRoleHours.interface';
import { Project } from '../interfaces/Project.interface';
import { ReportedHoursBagde } from './ReportedHoursBadge';
import { useSumEmployeeWorkHours } from '../hooks/useSumEmployeeWorkHours';
import { useTotalWorkHours } from '../hooks/useTotalWorkHours';
import { compareDates } from 'libs/frontend/utils/src/lib/compareDates';
import { useEmployeeLockDate } from '../hooks/useEmployeeLockDate';
import { UserRole } from '../enums/UserRole.enum';

interface TimeSheetTableProps {
  employeeId: string;
  daysOfWeek: Moment[];
  setPrevMonth?: () => void;
  setNextMonth?: () => void;
  setRangeDate: (startDate: Moment, endDate: Moment) => void;
  setCurrentWeek: () => void;
  startDate: Moment;
  endDate: Moment;
  daysRows: RowType<HeaderCell | ChangeWeekCell>[];
  authenticatedUserRole: UserRole;
}

export const TimeSheetTable: React.FC<TimeSheetTableProps> = ({
  daysOfWeek,
  daysRows,
  employeeId,
  setPrevMonth,
  startDate,
  endDate,
  setNextMonth,
  authenticatedUserRole,
  setCurrentWeek,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateHiddenProjects } = useHiddenProjects();
  const [searchParams] = useSearchParams();
  const showRecentTasks = searchParams.get('showRecentTasks') === 'true';
  const newSearchParams = new URLSearchParams(location.search);

  const [saving, setSaving] = useState(0);

  const handleChange = () => {
    newSearchParams.set('showRecentTasks', String(!showRecentTasks));
    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString(),
    });
  };

  const { employeeLockDate } = useEmployeeLockDate(employeeId);

  const { employeeProjects, isValidating, mutateEmployeeProjects } =
    useEmployeeProjects(employeeId, showRecentTasks);

  const { workTimespans, mutateWorkTimespans } =
    useDailyWorkTimespans(employeeId);

  const projectRows = useMemo(() => {
    if (!employeeProjects) {
      return [];
    }

    const projects = employeeProjects.filter((project) =>
      rejectArchivedProjectsWithoutLoggedTimeFilter(project, daysOfWeek),
    );

    const projectsWithNonEmptyWorkItemsArray = projects.filter(
      (project) => project.workItems.length > 0,
    );

    return projectsWithNonEmptyWorkItemsArray.flatMap((project) =>
      getWorkItemRows(
        project,
        daysOfWeek,
        employeeLockDate?.lockDate,
        authenticatedUserRole,
      ),
    );
  }, [daysOfWeek, employeeProjects]);

  const { totalWorkHours, mutateTotalWorkHours } = useTotalWorkHours(
    employeeId,
    startDate,
    endDate,
  );

  const hoursRows = useMemo(() => {
    if (employeeProjects && totalWorkHours) {
      const extractProjectHours = (day: Moment) => {
        return (
          totalWorkHours.find((workHour) =>
            moment(workHour.date).isSame(day, 'day'),
          )?.hours || 0
        );
      };

      const summedUpHours = daysOfWeek.map((day) => extractProjectHours(day));

      return getTotalRows(daysOfWeek, summedUpHours);
    }
    return [];
  }, [daysOfWeek, employeeProjects, totalWorkHours]);

  const workTimespansRows = useMemo(() => {
    if (workTimespans) {
      const currentWeekTimespans = daysOfWeek.map((day) =>
        workTimespans.find((timespan) =>
          moment(timespan.date).isSame(day, 'D'),
        ),
      );

      return getWorkingTimeRows(
        daysOfWeek,
        currentWeekTimespans,
        employeeLockDate?.lockDate,
        authenticatedUserRole,
      );
    }
    return [];
  }, [daysOfWeek, workTimespans]);

  const { mutateReportedHoursFirstMonth, mutateReportedHoursSecondMonth } =
    useSumEmployeeWorkHours(employeeId, startDate, endDate);

  if (isValidating && !employeeProjects) {
    return <Loader />;
  }

  const cellChangesHandler = async (changes: CellChange<WorkHoursCell>[]) => {
    const timetableEntitiesToUpsert: UpsertTimetableEntryDto[] = [];

    const workTimespansToUpsert: UpsertDailyWorkTimespanDto[] = [];

    const appendWorkTimespan = (timespan: UpsertDailyWorkTimespanDto) => {
      const idx = workTimespansToUpsert.findIndex((existingTimespan) =>
        compareDates(existingTimespan.date, timespan.date),
      );
      if (idx === -1) {
        const ts = workTimespans.find((existingTimespan) =>
          compareDates(existingTimespan.date, timespan.date),
        );

        workTimespansToUpsert.push({
          timeIn: ts?.timeIn,
          timeOut: ts?.timeOut,
          ...timespan,
        });
        return;
      }
      const freshTimespan = { ...workTimespansToUpsert[idx], ...timespan };
      workTimespansToUpsert[idx] = freshTimespan;
    };

    changes.forEach((change) => {
      const workItemId = change.rowId.toString();
      const hours = (change.newCell as NumberCell).value || 0;
      const previousHours = (change.previousCell as NumberCell).value;

      const newCellText = (change.newCell as TextCell).text;
      const prevCellText = (change.previousCell as TextCell).text;

      if (
        workItemId === 'workStart' &&
        (newCellText || (!newCellText && prevCellText))
      ) {
        appendWorkTimespan({
          employee: employeeId,
          timeIn: newCellText || null,
          date: new Date(change.columnId),
        });
        return;
      }

      if (
        workItemId === 'workEnd' &&
        (newCellText || (!newCellText && prevCellText))
      ) {
        appendWorkTimespan({
          employee: employeeId,
          timeOut: newCellText || null,
          date: new Date(change.columnId),
        });
        return;
      }

      if (
        change.columnId === 'projects-tree' &&
        (change.type === 'chevron' || change.type === 'nonEditableChevron')
      ) {
        mutateEmployeeProjects(
          (prevProjects) =>
            prevProjects.map((project) => {
              if (project.id === change.rowId) {
                updateHiddenProjects(project.id);
                return { ...project, hidden: !change.newCell.isExpanded };
              }
              return project;
            }),
          false,
        );
        return;
      }

      if (Object.is((change.newCell as NumberCell).value, previousHours)) {
        return;
      }

      if ((change.newCell as NumberCell).value <= 0) {
        toast.error(
          'Entries that are equal to 0 and less than 0 are not allowed',
        );
        return;
      }

      const workItemRoleHoursId = (change.newCell as RowCell)
        .workItemRoleHoursId;
      const collaboratorId = (change.newCell as RowCell).collaboratorId;
      const date = change.columnId.toString();

      if (change.rowId !== 'start-hours' && change.rowId !== 'pause-hours') {
        timetableEntitiesToUpsert.push({
          date,
          hours,
          workItemId,
          collaboratorId,
          workItemRoleHoursId,
        });
      }
    });

    if (workTimespansToUpsert.length) {
      sendRequest<UpsertDailyWorkTimespansDto>(
        Endpoint.DAILY_WORK_TIMESPANS,
        { entries: workTimespansToUpsert },
        'POST',
      ).then((res) => res.status === 201 && mutateWorkTimespans([]));
    }

    if (timetableEntitiesToUpsert.length > 0) {
      setSaving((saving) => saving + 1);

      const createdWorkItemRoleHours: WorkItemRoleHours[] = [];
      const missedWorkItemsId = timetableEntitiesToUpsert
        .filter((timetableEntity) => !timetableEntity.workItemRoleHoursId)
        .map((workItem) => workItem.workItemId);
      for (const workItemId of [...new Set(missedWorkItemsId)]) {
        const project = employeeProjects.find((project) =>
          project.workItems.find((workItem) => workItem.id === workItemId),
        );
        const workItemRoleHours = await sendRequest<
          CreateWorkItemRoleHoursDto,
          WorkItemRoleHours
        >(
          Endpoint.WORKITEM_ROLE_HOURS,
          {
            actualHours: 0,
            plannedHours: 0,
            roleId: project.collaborators[0].role?.id,
            workItemId,
          },
          'POST',
        );
        createdWorkItemRoleHours.push(workItemRoleHours.data);
      }

      const entriesToUpdate = timetableEntitiesToUpsert.map(
        (timetableEntryToUpsert) => ({
          ...timetableEntryToUpsert,
          workItemRoleHoursId:
            timetableEntryToUpsert.workItemRoleHoursId ||
            createdWorkItemRoleHours.find(
              (createdWorkItem) =>
                createdWorkItem.workItem.id ===
                timetableEntryToUpsert.workItemId,
            ).id,
        }),
      );

      let updatedProjects: Project[] = [];
      const unmodifiedEmployeeProjects = employeeProjects;
      entriesToUpdate.forEach(({ workItemId, date, hours }) => {
        updatedProjects = employeeProjects.map((project) => {
          const workItem = project.workItems.find(
            (workItem) => workItem.id === workItemId,
          );
          if (!workItem) {
            return project;
          }

          const timetableEntry = workItem.timetableEntries.find(
            (timetableEntry) =>
              workItem.timetableEntries.some(
                (te) =>
                  te.id === timetableEntry.id &&
                  moment(te.date).isSame(date, 'date'),
              ),
          );
          if (timetableEntry) {
            timetableEntry.hours = hours;
            if (hours === 0) {
              workItem.timetableEntries = workItem.timetableEntries.filter(
                ({ id }) => id !== timetableEntry.id,
              );
            }
          } else {
            const temporaryID = new Date().getTime().toString();
            workItem.timetableEntries.push({
              id: temporaryID,
              date: moment(date).format('YYYY-MM-DD'),
              hours,
            });
          }
          return project;
        });
      });
      await mutateEmployeeProjects(updatedProjects, false);

      sendRequest<UpsertTimetableEntriesDto, UpsertTimetableEntriesResponseDto>(
        Endpoint.TIMETABLE_ENTRIES,
        {
          entries: entriesToUpdate,
        },
        'POST',
      )
        .then(() => {
          mutateEmployeeProjects(updatedProjects, true);
          mutateReportedHoursFirstMonth(undefined, { revalidate: true });
          mutateReportedHoursSecondMonth(undefined, { revalidate: true });
          mutateTotalWorkHours([], true);
        })
        .catch((err) => {
          mutateEmployeeProjects(unmodifiedEmployeeProjects, true); // rollback
          console.error(err);
        })
        .finally(() => {
          setSaving((saving) => saving - 1);
        });
    }
  };

  const reactGridRows = [
    ...daysRows,
    ...projectRows,
    ...workTimespansRows,
    ...hoursRows,
  ];

  return (
    <div>
      <Row className="d-flex w-full justify-content-between">
        <Form.Group as={Row}>
          <ButtonGroup>
            <MonthSwitchButton onClick={setPrevMonth}>
              <ChevronLeft />
            </MonthSwitchButton>
            <DisplayDate>{daysOfWeek[6].format('MMMM YYYY')}</DisplayDate>
            <MonthSwitchButton onClick={setNextMonth}>
              <ChevronRight />
            </MonthSwitchButton>
          </ButtonGroup>
        </Form.Group>
        <div className="d-flex align-items-center">
          <Button
            size="sm"
            style={{ height: 'fit-content' }}
            onClick={() => setCurrentWeek()}
          >
            Set current week
          </Button>
        </div>
      </Row>

      <Form.Group
        as={Row}
        className="mr-0 justify-content-between align-items-center"
      >
        <div>
          <Form.Check
            type="radio"
            label="only recent tasks"
            name="formHorizontalRadios"
            inline
            checked={showRecentTasks}
            onChange={handleChange}
            id="recent-tasks-radio-1"
          />
          <Form.Check
            type="radio"
            label="all tasks"
            inline
            name="formHorizontalRadios"
            checked={!showRecentTasks}
            onChange={handleChange}
            id="recent-tasks-radio-2"
          />
        </div>

        <Row>
          <Badge className="mr-2">
            {authenticatedUserRole === UserRole.EMPLOYEE &&
              (employeeLockDate?.isDefaultLockDate
                ? 'Lock time until the last day of the previous month'
                : `Lock time until ${employeeLockDate?.lockDate}`)}
          </Badge>
          <ReportedHoursBagde
            employeeId={employeeId}
            firstDay={startDate}
            lastDay={endDate}
          />
        </Row>
      </Form.Group>

      <Row className="position-relative">
        <LoadingOverlay show={saving > 0}>
          {projectRows && hoursRows && (
            <ReactGrid
              columns={getTimeSheetTableColumns(daysOfWeek)}
              customCellTemplates={{
                changeWeek: new ChangeWeekCellTemplate(),
                nonEditableChevron: new NonEditableChevronCellTemplate(),
                extendedHeader: new ExtendedHeaderCellTemplate(),
              }}
              enableColumnSelection
              onCellsChanged={cellChangesHandler}
              rows={
                saving > 0 ? makeCellsNonEditable(reactGridRows) : reactGridRows
              }
              stickyTopRows={2}
              stickyBottomRows={1}
              enableRangeSelection
              enableFillHandle
            />
          )}
        </LoadingOverlay>
      </Row>
    </div>
  );
};

const MonthSwitchButton = styled.button`
  display: flex;
  width: 50px;
  height: 100%;
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 30px;
`;

const DisplayDate = styled.div`
  width: 135px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;
