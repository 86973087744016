import { useFormik } from 'formik';
import React, { FC } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import * as Yup from 'yup';
import { useEmployeeProjects } from '../hooks/useEmployeeProjects';
import { filterOptionByLabel, SelectOption } from '@portal/frontend/utils';
import {
  ChangePackageWorkItemId,
  ReassignWorkItemFormValues,
} from '../interfaces/ChangeWorkItem.interface';
import { WorkItemStatus } from '../enums/WorkItemStatus.enum';

interface ChangeWorkPackageModalProps {
  employeeId: string;
  projectDetails: SelectOption;
  workItemDetails: SelectOption;
  onHide: () => void;
  onSave: (values: ChangePackageWorkItemId) => void;
  show: boolean;
}

const initialTouched = {
  projectId: false,
  workItemId: false,
};

const validationSchema = Yup.object().shape({
  projectId: Yup.string().required('Project is required'),
  workItemId: Yup.string().required('Work item is required'),
});

export const ChangeWorkPackageModal: FC<ChangeWorkPackageModalProps> = ({
  onHide,
  show,
  onSave,
  projectDetails,
  workItemDetails,
  employeeId,
}) => {
  const { employeeProjects } = useEmployeeProjects(employeeId, false);

  const projectOptions = employeeProjects
    ?.filter(
      (project) =>
        project.deletedAt === null &&
        project.collaborators[0].deletedAt === null &&
        project.workItems.some(
          (workItem) => workItem.status !== WorkItemStatus.ARCHIVED,
        ),
    )
    .map((project) => ({
      value: project.id,
      label: project.name,
    }));

  const { handleSubmit, values, setFieldValue, isValid, dirty } =
    useFormik<ReassignWorkItemFormValues>({
      initialValues: {
        projectId: projectDetails.value,
        workItemId: '',
      },
      initialTouched: initialTouched,
      onSubmit: (values) => {
        onSave({
          workItemId: values.workItemId,
        });
      },
      enableReinitialize: true,
      validationSchema,
    });

  const filteredWorkItemOptions: SelectOption[] = employeeProjects
    ?.find((project) => project.id === values.projectId)
    .workItems.filter(
      (workItem) =>
        workItem.id !== workItemDetails.value &&
        workItem.status !== WorkItemStatus.ARCHIVED,
    )
    .map((workItem) => {
      return {
        label: workItem.name,
        value: workItem.id,
      };
    });

  const handleClose = () => {
    onHide();
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Change package
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Col>
            <Form.Group>
              <Form.Label>Select Project</Form.Label>
              <Select<SelectOption, false>
                defaultValue={projectDetails}
                options={projectOptions}
                isSearchable
                filterOption={filterOptionByLabel}
                onChange={(selectedOption) => {
                  setFieldValue('projectId', selectedOption.value);
                  setFieldValue('workItemId', '');
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Select work item</Form.Label>
              <Select<SelectOption, false>
                options={filteredWorkItemOptions}
                defaultValue={[]}
                value={{
                  value: values.workItemId,
                  label:
                    filteredWorkItemOptions?.find(
                      ({ value }) => value === values.workItemId,
                    )?.label ?? '',
                }}
                isSearchable
                filterOption={filterOptionByLabel}
                onChange={(selectedOption) => {
                  setFieldValue('workItemId', selectedOption.value);
                }}
              />
            </Form.Group>
          </Col>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
        <Button disabled={!(isValid && dirty)} onClick={() => handleSubmit()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeWorkPackageModal;
