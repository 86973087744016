import { CellStyle } from '@silevis/reactgrid';

export const totalLineCellStyle: CellStyle = {
  border: {
    bottom: {
      color: '#000000',
      width: '5px',
    },
  },
};
