import { useRequest } from './useRequest';
import Endpoint from '../enums/apiEndpoints';
import { EmployeeLockTimeDto } from '@portal/client-portal-api-model';

export function useEmployeeLockDate(employeeId: string) {
  const { data: employeeLockDate } = useRequest<EmployeeLockTimeDto>({
    url: `${Endpoint.EMPLOYEE_LOCK_TIME}`,
    params: { employeeId },
  });

  return {
    employeeLockDate,
  };
}
