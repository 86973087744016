import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useHiddenProjects = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const hiddenProjects = JSON.parse(
    searchParams.get('hiddenProjects') || '[]',
  ) as string[];

  const updateHiddenProjects = useCallback(
    (projectId: string) => {
      const newHiddenProjects = hiddenProjects.find((id) => id === projectId)
        ? hiddenProjects.filter((id) => id !== projectId)
        : [...hiddenProjects, projectId];
      searchParams.set('hiddenProjects', JSON.stringify(newHiddenProjects));
      setSearchParams(searchParams);
    },
    [hiddenProjects, searchParams, setSearchParams],
  );

  const removeHiddenProjectsFromSearchParams = useCallback(() => {
    searchParams.delete('hiddenProjects');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return {
    updateHiddenProjects,
    removeHiddenProjectsFromSearchParams,
    isProjectHidden: (projectId: string): boolean =>
      hiddenProjects.includes(projectId),
  };
};
