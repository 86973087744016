import { useHiddenProjects } from './useHiddenProjects';
import { sortByExtId, sortByName } from '@portal/frontend/utils';
import { useRequest } from './useRequest';
import Endpoint from '../enums/apiEndpoints';
import { Project } from '../interfaces/Project.interface';
import { useTimesheetDate } from './useTimesheetDate';

export function useEmployeeProjects(
  employeeId: string,
  showRecentTasks: boolean,
) {
  const { formattedStartDate, formattedEndDate } = useTimesheetDate();

  const params = new URLSearchParams({
    showRecentTasks: showRecentTasks ? 'true' : 'false',
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  });

  const {
    data: projects,
    response,
    error,
    isValidating,
    mutate,
    mutateData,
  } = useRequest<Project[]>(
    {
      url: `${Endpoint.PROJECTS_EMPLOYEE}/${employeeId}?${params}`,
    },
    { keepPreviousData: true },
  );

  const { isProjectHidden } = useHiddenProjects();

  return {
    employeeProjects: projects?.sort(sortByName).map<Project>((project) => ({
      ...project,
      hidden: isProjectHidden(project.id),
      workItems: project.workItems.sort(sortByExtId),
    })),
    employeeProjectsResponse: response,
    error,
    isValidating,
    mutateEmployeeProjectsResponse: mutate,
    mutateEmployeeProjects: mutateData,
  };
}
