import Axios, { AxiosResponse } from 'axios';

// TODO change order of arguments
export const sendRequest = async <Data = any, Response = any>(
  url: string,
  data: Data,
  method: 'POST' | 'DELETE' | 'GET' | 'PATCH',
): Promise<AxiosResponse<Response>> => {
  const response = await Axios({
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    url,
    data: JSON.stringify(data),
  });
  return response;
};
