import React from 'react';
import {
  CellTemplate,
  Uncertain,
  Compatible,
  Cell,
  UncertainCompatible,
} from '@silevis/reactgrid';

export interface CustomDropdownCell extends Cell {
  type: 'customDropdown';
  values: string[];
  text: string;
  disabled?: boolean;
}
export class CustomDropdownCellTemplate
  implements CellTemplate<CustomDropdownCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<CustomDropdownCell>
  ): Compatible<CustomDropdownCell> {
    return {
      ...uncertainCell,
      text: uncertainCell.text || '',
      value: uncertainCell.values?.length || 0,
      values: uncertainCell.values || [],
      disabled: uncertainCell.disabled,
      className: uncertainCell.className,
    };
  }

  update(
    cell: Compatible<CustomDropdownCell>,
    cellToMerge: UncertainCompatible<CustomDropdownCell>
  ): Compatible<CustomDropdownCell> {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
  }

  render(
    cell: Compatible<CustomDropdownCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<CustomDropdownCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return (
      <select
        disabled={cell.disabled}
        className="browser-default custom-select"
        onPointerDown={(e) => e.stopPropagation()}
        value={cell.text}
        onChange={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            true
          )
        }
      >
        {cell.values.map((value, idx) => (
          <option key={idx} value={value} className={`shift-option${idx}`}>
            {value}
          </option>
        ))}
      </select>
    );
  }
}
