import { NavigationBar, NavItem } from '@portal/frontend/react';
import React from 'react';
import { NAV_ITEMS } from '../utils/navigation.routes';

export const NavBar = () => {
  return (
    <NavigationBar>
      {NAV_ITEMS.map((item) => (
        <NavItem key={item.path} path={item.path} title={item.title} />
      ))}
    </NavigationBar>
  );
};
