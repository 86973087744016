import * as React from 'react';
import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
  getCellProperty,
  getCharFromKeyCode,
  isAlphaNumericKey,
  keyCodes,
  UncertainCompatible,
  isNavigationKey,
} from '@silevis/reactgrid';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface ExtendedTextCell extends Cell {
  type: 'extendedText';
  text: string;
  placeholder?: string;
  validator?: (text: string) => boolean;
  renderer?: (text: string) => React.ReactNode;
}

export class ExtendedTextCellTemplate
  implements CellTemplate<ExtendedTextCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<ExtendedTextCell>
  ): Compatible<ExtendedTextCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let placeholder: string | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
      placeholder = '';
    }
    const value = parseFloat(text); // TODO more advanced parsing for all text based cells
    return { ...uncertainCell, text, value, placeholder };
  }

  update(
    cell: Compatible<ExtendedTextCell>,
    cellToMerge: UncertainCompatible<ExtendedTextCell>
  ): Compatible<ExtendedTextCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder,
    });
  }

  handleKeyDown(
    cell: Compatible<ExtendedTextCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<ExtendedTextCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift);
    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE)
    )
      return {
        cell: this.getCompatibleCell({
          ...cell,
          text: shift ? char : char.toLowerCase(),
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  getClassName(
    cell: Compatible<ExtendedTextCell>,
    isInEditMode: boolean
  ): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className ? cell.className : '';
    return `${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' ? 'placeholder' : ''
    } ${className}`;
  }

  render(
    cell: Compatible<ExtendedTextCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<ExtendedTextCell>, commit: boolean) => void
  ) {
    if (!isInEditMode) {
      const textToDisplay =
        cell.text === '' ? cell.placeholder || '' : cell.text;
      return (
        <OverlayTrigger
          placement={'top-start'}
          delay={{
            hide: 100,
            show: 500,
          }}
          overlay={<Tooltip id={`tooltip-top`}>{cell.text}</Tooltip>}
        >
          <span className={'text-overflow-ellipsis'}>
            {cell.renderer ? cell.renderer(textToDisplay) : textToDisplay}
          </span>
        </OverlayTrigger>
      );
    }

    return (
      <input
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.text}
        onChange={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            false
          )
        }
        onBlur={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            true
          )
        }
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
            e.stopPropagation();
        }}
      />
    );
  }
}
