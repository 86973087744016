import React from 'react';
import {
  CellTemplate,
  Uncertain,
  Compatible,
  Cell,
  UncertainCompatible,
} from '@silevis/reactgrid';
import { Button } from 'react-bootstrap';
import { Archive } from 'react-bootstrap-icons';

export interface ArchiveButtonCell extends Cell {
  type: 'archiveButton';
  text: string;
  disabled?: boolean;
}
export class ArchiveButtonCellTemplate
  implements CellTemplate<ArchiveButtonCell>
{
  getCompatibleCell(
    uncertainCell: Uncertain<ArchiveButtonCell>
  ): Compatible<ArchiveButtonCell> {
    return {
      ...uncertainCell,
      text: uncertainCell.text || '',
      disabled: uncertainCell.disabled,
      className: uncertainCell.className,
      value: NaN,
    };
  }

  update(
    cell: Compatible<ArchiveButtonCell>,
    cellToMerge: UncertainCompatible<ArchiveButtonCell>
  ): Compatible<ArchiveButtonCell> {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
  }

  render(
    cell: Compatible<ArchiveButtonCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<ArchiveButtonCell>,
      commit: boolean
    ) => void
  ): React.ReactNode {
    return (
      <div className="d-flex justify-content-center w-100">
        <Button
          variant="light"
          onClick={(e) =>
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: 'clicked' }),
              true
            )
          }
        >
          <Archive fill={cell.disabled ? '#ccc' : ''} />
        </Button>
      </div>
    );
  }
}
