import { Column } from '@silevis/reactgrid';
import {
  FIRST_LEFT_COL_WIDTH,
  IDS_COL_WIDTH,
  HOURS_WIDTH,
  DESCRIPTION_COL_WIDTH,
  CHEVRON_COL_WIDTH,
} from '../utils/consts';

export const getColumns = (): Column[] => [
  { columnId: 'day-in-week', width: FIRST_LEFT_COL_WIDTH - HOURS_WIDTH },
  { columnId: 'id-workitem', width: IDS_COL_WIDTH },
  { columnId: 'hours', width: HOURS_WIDTH },
  { columnId: 'description', width: DESCRIPTION_COL_WIDTH + CHEVRON_COL_WIDTH },
];
