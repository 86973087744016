import moment from 'moment';
import { Moment } from 'moment-business-days';
import { style2 } from './getDaysOfWeekRows';
import { Row } from '@silevis/reactgrid';
import { getHeaderCell, getTimeCell } from '../templates/cellGetters';
import { compareDates } from 'libs/frontend/utils/src/lib/compareDates';
import { DeveloperPortalCellTypes } from '../interfaces/reactgrid.interface';
import { shouldBeLocked } from './shouldBeLocked';
import { UserRole } from '../enums/UserRole.enum';

const timeFormat = new Intl.DateTimeFormat('pl', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  timeZone: 'Poland',
});

export const getWorkingTimeRows = (
  days: Moment[],
  timestamps: { timeIn: string; timeOut: string }[],
  employeeLockDate: string,
  employeeRole: UserRole,
): Row<DeveloperPortalCellTypes>[] => {
  const getStyles = (day: Moment) =>
    compareDates(day.toDate(), new Date()) && style2;

  return [
    {
      rowId: 'workStart',
      height: 35,
      cells: [
        getHeaderCell('Time in', {}, 'top-line-style'),
        getHeaderCell('', {}, 'top-line-style'),
        getHeaderCell('', {}, 'top-line-style'),
        ...days.map((day, idx) => {
          const isTimeLoggingLocked = shouldBeLocked(
            moment(employeeLockDate, 'DD-MM-YYYY').toDate(),
            moment(day).toDate(),
            employeeRole,
          );
          return getTimeCell(
            new Date(
              `${moment(day).format('YYYY-MM-DD')} ${timestamps[idx]?.timeIn}`,
            ),
            timeFormat,
            undefined,
            getStyles(day),
            isTimeLoggingLocked
              ? 'non-editable-cell top-line-style'
              : 'top-line-style',
            isTimeLoggingLocked,
          );
        }),
        getHeaderCell('', {}, 'top-line-style'),
      ],
    },
    {
      rowId: 'workEnd',
      height: 35,
      cells: [
        getHeaderCell('Time out'),
        getHeaderCell(''),
        getHeaderCell(''),
        ...days.map((day, idx) => {
          const isTimeLoggingLocked = shouldBeLocked(
            moment(employeeLockDate, 'DD-MM-YYYY').toDate(),
            moment(day).toDate(),
            employeeRole,
          );

          return getTimeCell(
            new Date(
              `${moment(day).format('YYYY-MM-DD')} ${timestamps[idx]?.timeOut}`,
            ),
            timeFormat,
            undefined,
            getStyles(day),
            isTimeLoggingLocked ? 'non-editable-cell' : '',
            isTimeLoggingLocked,
          );
        }),
        getHeaderCell(''),
      ],
    },
  ];
};
