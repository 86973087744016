import { formatter } from '@portal/frontend/utils';
import React from 'react';
import { Table } from 'react-bootstrap';
import { TableHeader } from '@portal/frontend/react';

const ReportResultTable = ({ employeeReport, showHours, noBreakdown }) => {
  const colSpan = noBreakdown ? (showHours ? 1 : 2) : 3;

  return (
    <>
      {!employeeReport && <span>No data to display...</span>}
      {employeeReport && (
        <Table
          bordered
          hover
          size="sm"
          style={{ width: '100%', borderCollapse: 'collapse' }}
        >
          <TableHeader
            showHours={showHours}
            showCosts={false}
            noBreakdown={noBreakdown}
          />
          <thead style={{ backgroundColor: '#9FC7E7' }}>
            <tr key={employeeReport.id}>
              <th colSpan={colSpan}>{employeeReport.name}</th>
              {showHours && (
                <th
                  className="font-weight-bold"
                  style={{
                    textAlign: 'right',
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 'normal',
                    width: '1px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {formatter.format(
                    employeeReport.collaborators
                      .flatMap((collaborator) =>
                        collaborator.timetableEntries.map(
                          (entry) => entry.hours
                        )
                      )
                      .reduce((a, b) => a + b)
                  )}
                </th>
              )}
            </tr>
          </thead>
          {employeeReport.collaborators.map((collaborator) => (
            <React.Fragment key={collaborator.id}>
              <thead>
                <tr key={collaborator.id}>
                  <th
                    style={{ whiteSpace: 'nowrap', maxWidth: '1px' }}
                    colSpan={colSpan}
                  >
                    {`${collaborator.project.name} ${
                      collaborator.project.deletedAt ? '(archived)' : ''
                    }`}
                  </th>
                  {showHours && (
                    <th
                      className="font-weight-bold"
                      style={{
                        textAlign: 'right',
                        fontFamily: 'inherit',
                        fontSize: 'inherit',
                        fontWeight: 'normal',
                        width: '1px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {formatter.format(
                        collaborator.timetableEntries
                          .map((entry) => entry.hours)
                          .reduce((a, b) => a + b)
                      )}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {!noBreakdown &&
                  collaborator.timetableEntries.map((entry) => {
                    return (
                      <tr key={entry.id}>
                        <td style={{ width: '1px', whiteSpace: 'nowrap' }}>
                          {entry.date}
                        </td>
                        <td
                          style={{
                            overflowWrap: 'break-word',
                            maxWidth: '100%',
                          }}
                        >{`#${entry.workItem.extId} ${entry.workItem.name}`}</td>
                        <td
                          style={{
                            overflowWrap: 'break-word',
                            maxWidth: '100%',
                          }}
                        >
                          {entry.description}
                        </td>
                        {showHours && (
                          <td
                            style={{
                              width: '1px',
                              whiteSpace: 'nowrap',
                              textAlign: 'right',
                            }}
                          >
                            {formatter.format(entry.hours)}
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </React.Fragment>
          ))}
        </Table>
      )}
    </>
  );
};

export default ReportResultTable;
