export enum TimeRange {
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM = 'CUSTOM',
}

export interface SelectOption {
  label: string;
  value: string;
}

export const timeSelectRangeOptions: SelectOption[] = [
  {
    label: 'Custom',
    value: TimeRange.CUSTOM,
  },
  {
    label: 'This week',
    value: TimeRange.THIS_WEEK,
  },
  {
    label: 'Last week',
    value: TimeRange.LAST_WEEK,
  },
  {
    label: 'This month',
    value: TimeRange.THIS_MONTH,
  },
  {
    label: 'Last month',
    value: TimeRange.LAST_MONTH,
  },
  {
    label: 'This year',
    value: TimeRange.THIS_YEAR,
  },
  {
    label: 'Last year',
    value: TimeRange.LAST_YEAR,
  },
];

export const defaultTimeRangeOption = timeSelectRangeOptions.find((option) => {
  return option.value === TimeRange.THIS_MONTH;
});

export const customTimeRangeOption = timeSelectRangeOptions.find((option) => {
  return option.value === TimeRange.CUSTOM;
});
