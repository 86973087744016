import React, { FC } from 'react';
import { ExclamationCircleFill } from 'react-bootstrap-icons';

interface ToastContentProps {
  title: string;
  message?: string | string[];
}

export const ToastContent: FC<ToastContentProps> = ({
  message = '',
  title,
  children,
}) => {
  return (
    <>
      <div className="d-flex">
        <div>
          <ExclamationCircleFill className="mr-3" />
        </div>
        <div>
          <h6 className="font-weight-bold pt-1">{title}</h6>
          {Array.isArray(message) ? (
            <ul className="mb-0" style={{ paddingInlineStart: 0 }}>
              {message.map((msg) => (
                <li key={msg}>{msg}</li>
              ))}
            </ul>
          ) : (
            <p className="mb-0">{message}</p>
          )}
          {children}
        </div>
      </div>
    </>
  );
};
