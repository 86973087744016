import React, { FC, ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';


interface ActionSpinnerProps {
  showSpinner: boolean;
  spinnerContent?: ReactNode;
  defaultText: string;
}

export const ActionSpinner: FC<ActionSpinnerProps> = ({
  showSpinner,
  spinnerContent = '',
  defaultText = '',
}) => {
  if (showSpinner) {
    return (
      <span>
        <Spinner as="span" aria-hidden="true" animation="border" role="status" size="sm" className='mr-2' />
        {spinnerContent}
      </span>
    )
  }
  return (
    <>
      {defaultText}
    </>
  );
}
