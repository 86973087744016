import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { NavBar } from '../components/NavBar';

export const TimeSheetView: React.FC = () => {
  return (
    <>
      <NavBar />
      <Container fluid className="mb-5">
        <Row as={'main'}>
          <Container className="pt-3">
            <Outlet />
          </Container>
        </Row>
      </Container>
    </>
  );
};
