import { EmployeeDto } from '@portal/client-portal-api-model';
import { useEffect } from 'react';
import { useGlobalConfig } from '../components/GlobalConfigProvider';
import { useAccessToken } from './useAccessToken';
import { useMe } from './useMe';

export function useCurrentUser(): EmployeeDto | undefined {
  const accessToken = useAccessToken();
  if (!accessToken.decodedToken) {
    return null;
  }

  const { dispatch } = useGlobalConfig();
  const { me, isValidating, error } = useMe();

  useEffect(() => {
    if (me && !isValidating && !error) {
      dispatch({ type: 'UPDATE_EMPLOYEE', payload: me });
    }
  }, [dispatch, me, error, isValidating]);

  return me;
}
