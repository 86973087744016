export enum WorkItemStatus {
  IDEA = 'Idea',
  IN_PREPARATION = 'In preparation',
  ESTIMATED = 'Estimated',
  ORDERED = 'Ordered',
  IN_WORK = 'In work',
  IN_REVIEW = 'In review',
  ACCEPTED = 'Accepted',
  BILLED = 'Billed',
  REOPENED = 'Reopened',
  ARCHIVED = 'Archived',
}
