import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap';

interface LoaderProps {
  text?: string;
  className?: string;
  fullScreen?: boolean;
  size?: 'sm';
}

export const Loader: FC<LoaderProps> = ({
  text,
  fullScreen,
  className = '',
  size,
}) => {
  return (
    <div
      className={`${
        fullScreen
          ? 'd-flex w-100 h-100 justify-content-center align-items-center'
          : ''
      } ${className}`}
    >
      <div className="d-flex align-items-center">
        <Spinner
          variant="primary"
          animation="border"
          role="status"
          size={size ? 'sm' : undefined}
          className="d-inline-block"
          aria-hidden="true"
        />
        <span className={`d-inline-block ${size ? 'font-16' : 'font-20'} ml-3`}>
          {text || 'Loading...'}
        </span>
      </div>
    </div>
  );
};
