import Axios from 'axios';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import Endpoint from '../enums/apiEndpoints';
import { useAccessToken } from '../hooks/useAccessToken';
import { Loader } from '../components/Loader';

export const ProfileView = () => {
  const accessToken = useAccessToken();
  if (!accessToken.decodedToken) {
    return null;
  }
  const { sub: employeeId } = accessToken.decodedToken;
  const [isLoading, setIsLoading] = useState(false);
  const [me, setMe] = useState({ email: '', name: '', surname: '' });

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const { data } = await Axios.get(`${Endpoint.EMPLOYEES}/${employeeId}`);
    setMe(data);
    setIsLoading(false);
  }, [employeeId]);

  useEffect(() => {
    fetchData().catch();
  }, [fetchData]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const { handleSubmit, handleChange, errors, setErrors } = useFormik<any>({
    initialValues: { confirmPassword: '', password: '' },
    onSubmit: (values) => handleSubmitForm(values),
    validationSchema,
  });

  const handleSubmitForm = async (values: { password: string }) => {
    await Axios.post(`${Endpoint.EMPLOYEES}/${employeeId}`, {
      password: values.password,
    })
      .then()
      .catch((error) => {
        setErrors({
          submit: error.response.data.message || 'connection error',
        });
      });
  };

  if (isLoading) {
    return <Loader fullScreen></Loader>;
  }

  return (
    <>
      <Row>
        <Col xs={12} md={5} lg={4}>
          <div className="font-weight-bold mt-3">Name</div>
          {`${me.name} ${me.surname}`}
          <div className="font-weight-bold mt-3">Email</div>
          {me.email}
        </Col>
        <Col xs={12} md={7} lg={8}>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <label htmlFor="password">Password</label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password *"
                required
                onChange={handleChange}
              />
              <Form.Text className="error"> {errors.password} </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <label htmlFor="confirmPassword">Confirm password</label>
              <Form.Control
                type="password"
                name="confirmPassword"
                required
                placeholder="Confirm password *"
                onChange={handleChange}
              />
              <Form.Text className="error">
                {' '}
                {errors.confirmPassword}{' '}
              </Form.Text>
            </Form.Group>
            <div style={{ display: 'flex' }}>
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {' '}
                Change Password
              </Button>
            </div>
          </Form>
          <div style={{ padding: '5px' }}>
            {errors.submit && <Alert variant={'danger'}>{errors.submit}</Alert>}
          </div>
        </Col>
      </Row>
    </>
  );
};
