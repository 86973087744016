import { Slide, ToastContainerProps } from 'react-toastify';

export const toastConfig: ToastContainerProps = {
  autoClose: 3000,
  hideProgressBar: false,
  transition: Slide,
  draggablePercent: 60,
  pauseOnHover: true,
  closeButton: true,
  position: 'bottom-right',
  newestOnTop: true,
};
