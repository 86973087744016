import React, { FC } from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { NavItemProps } from '../interfaces/NavItemProps.interface';
import styled from 'styled-components';

const StyledNavLink = styled(Nav.Link).attrs((props) => ({
  isactive: props.isactive === 'true' ? props.isactive : undefined, // looks ugly :(
}))`
  color: black;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid
    ${(props) => (props.isactive ? 'black' : 'transparent')};
  height: 2rem;
  &:hover {
    color: unset;
    text-decoration: unset;
  }
`;

export const NavItem: FC<NavItemProps> = ({ path, title }) => {
  const { pathname } = useLocation();

  return (
    <Nav.Item>
      <StyledNavLink
        as={Link}
        to={path}
        isactive={pathname.startsWith(path) ? 'true' : 'false'}
        className={'mr-5'}
      >
        {title}
      </StyledNavLink>
    </Nav.Item>
  );
};
