import moment from 'moment';
import { Project } from '../interfaces/Project.interface';

export const rejectArchivedProjectsWithoutLoggedTimeFilter = (
  project: Project,
  daysOfWeek: moment.Moment[],
): boolean => {
  const isProjectArchived = project.deletedAt;
  if (!isProjectArchived) {
    return true;
  }
  const hasLoggedTimeOnCurrentlyVisibleWeek = project.workItems.some(
    (workItem) => {
      const hasEmptyTimeTable = workItem.timetableEntries.length === 0;
      if (hasEmptyTimeTable) {
        return false;
      }
      const hasLoggedTimeOnCurrentlyVisibleWeek =
        workItem.timetableEntries.some(({ date }) => {
          return moment(date).isBetween(
            daysOfWeek[0],
            daysOfWeek[6],
            'day',
            '[]',
          );
        });
      return hasLoggedTimeOnCurrentlyVisibleWeek;
    },
  );
  return hasLoggedTimeOnCurrentlyVisibleWeek;
};
