import { EmployeeDto } from '@portal/client-portal-api-model';
import Endpoint from '../enums/apiEndpoints';
import { useRequest } from './useRequest';

export function useEmployee(employeeId: string | undefined) {
  const {
    data: employee,
    response,
    error,
    isValidating,
    mutate,
    mutateData,
  } = useRequest<EmployeeDto>({
    url: employeeId ? `${Endpoint.EMPLOYEES}/${employeeId}` : null,
  });

  return {
    employee: employee,
    employeeResponse: response,
    error,
    isValidating,
    mutateEmployeeResponse: mutate,
    mutateEmployee: mutateData,
  };
}
