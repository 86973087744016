import { ReportsFiltersDto } from '@portal/developer-portal-api-model';
import { Loader, ToggleSwitch, useAccessToken } from '@portal/frontend/react';
import { getFirstDayOfMonth, getLastDayOfMonth } from '@portal/frontend/utils';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ReportResultTable from '../components/ReportResultTable';
import ReportsForm from '../components/ReportsForm';
import { useEmployeeReport } from '../hooks/useEmployeeReport';

const ReportsView = () => {
  const [showHours, setShowHours] = useState(true);
  const [noBreakdown, setNoBreakdown] = useState(false);
  const [filters, setFilters] = useState<ReportsFiltersDto>({
    startDate: moment(getFirstDayOfMonth(moment().toDate())).format(
      'YYYY-MM-DD',
    ),
    finishDate: moment(getLastDayOfMonth(moment().toDate())).format(
      'YYYY-MM-DD',
    ),
  });
  const { decodedToken } = useAccessToken();
  const { sub: employeeId } = decodedToken;

  const { employeeReport, isValidating } = useEmployeeReport(
    filters,
    employeeId,
  );

  const handleOnChangeShowHours = (show: boolean) => {
    setShowHours(show);
  };

  const handleOnChangeNoBreakdown = () => {
    setNoBreakdown((noBreakdown) => !noBreakdown);
  };

  return (
    <>
      <Row className="pb-3 d-print-none">
        <ReportsForm
          onSubmitHandler={(values) => {
            setFilters(values);
          }}
        />
      </Row>
      <Row className="mb-3 d-print-none">
        <Col md="auto" className="d-flex align-items-center">
          <ToggleSwitch
            name="Show hours"
            onChange={handleOnChangeShowHours}
            checked={showHours}
          />
        </Col>
        <Col md="auto" className="d-flex align-items-center">
          <ToggleSwitch
            name="No breakdown"
            onChange={handleOnChangeNoBreakdown}
            checked={noBreakdown}
          />
        </Col>
        <Col md="auto">
          <Button onClick={() => window.print()} variant="outline-primary">
            Print report
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {isValidating ? (
            <Loader fullScreen />
          ) : (
            <ReportResultTable
              employeeReport={employeeReport}
              noBreakdown={noBreakdown}
              showHours={showHours}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ReportsView;
