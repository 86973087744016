import { AppRoutes } from '@portal/frontend/utils';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAccessToken } from '../hooks/useAccessToken';

export const PrivateRoute = ({ children }) => {
  const { accessToken, decodedToken } = useAccessToken();
  const isLogged = !!accessToken && !!decodedToken;

  return isLogged ? children : <Navigate to={AppRoutes.SIGNIN} replace />;
};
