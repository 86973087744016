import {
  ReportResponseDto,
  ReportsFiltersDto,
} from '@portal/developer-portal-api-model';
import { useRequest } from '@portal/frontend/react';
import Endpoint from '../enums/apiEndpoints';

export function useEmployeeReport(
  reportFilters: ReportsFiltersDto | null,
  employeeId: string,
) {
  const url = reportFilters ? `${Endpoint.REPORT}/${employeeId}` : null;

  const {
    data: report,
    response,
    error,
    isValidating,
    mutate,
    mutateData,
  } = useRequest<ReportResponseDto>(
    {
      url,
      data: reportFilters,
      method: 'POST',
    },
    {
      initialData: undefined,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  return {
    employeeReport: report,
    employeeReportResponse: response,
    error,
    isValidating,
    mutateEmployeeReportResponse: mutate,
    mutateEmployeeReport: mutateData,
  };
}
