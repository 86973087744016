import { EmployeeDto } from '@portal/client-portal-api-model';
import Endpoint from '../enums/apiEndpoints';
import { useRequest } from './useRequest';

export function useEmployees() {
  const {
    data: employees,
    response,
    error,
    isValidating,
    mutate,
    mutateData,
  } = useRequest<EmployeeDto[]>({
    url: Endpoint.EMPLOYEES,
  });

  return {
    employees,
    employeesResponse: response,
    error,
    isEmployeesValidating: isValidating,
    mutateEmployeesResponse: mutate,
    mutateEmployees: mutateData,
  };
}
