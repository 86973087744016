import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@portal/frontend/utils';
import { useAccessToken } from '../hooks/useAccessToken';
import { toast } from 'react-toastify';

export const Logout: FC = ({ children }) => {
  const { removeCookie } = useAccessToken();

  const navigate = useNavigate();

  const handleLogOut = () => {
    toast.success('You have been logged out');
    removeCookie();
    navigate(AppRoutes.SIGNIN);
  };

  return (
    <>
      <span onClick={handleLogOut}>{children || `Log out`}</span>
    </>
  );
};
