import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { LoginDto } from '@portal/developer-portal-api-model';
import { AppRoutes, sendRequest } from '@portal/frontend/utils';
import { Col, Container, Row } from 'react-bootstrap';
import { useAccessToken } from '../hooks/useAccessToken';
import { AuthResponse } from '@portal/nest/auth-module';
import { useIsMounted } from '../hooks/useIsMounted';
import { ActionSpinner } from '../components/ActionSpinner';
import { useGlobalConfig } from '../components/GlobalConfigProvider';
import Endpoint from '../enums/apiEndpoints';
import { HistoryPushCause } from '../enums/HistoryPushCause.enum';

interface LoginViewProps {
  redirectUrl?: string;
}

interface LoginFormFields {
  email: string;
  password: string;
  submit?: any;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('email must be a valid').required(),
  password: Yup.string().min(4).required(),
});

export const LoginView: FC<LoginViewProps> = ({
  redirectUrl = AppRoutes.HOME,
}) => {
  const { title } = useGlobalConfig();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();
  const { removeCookie } = useAccessToken();

  const navigate = useNavigate();
  const location = useLocation();
  const { setCookie } = useAccessToken();

  useEffect(() => {
    if (location.state === HistoryPushCause.UNAUTHORIZED) {
      removeCookie();
    }
  }, []);

  const { handleSubmit, handleChange, errors, setErrors } =
    useFormik<LoginFormFields>({
      initialValues: { email: '', password: '' },
      onSubmit: (values) => handleSubmitForm(values),
      validationSchema,
    });

  const handleSubmitForm = async (employee: LoginFormFields) => {
    const { email, password } = employee;
    setLoading(true);
    sendRequest<LoginDto, AuthResponse>(
      Endpoint.LOGIN,
      { email, password },
      'POST',
    )
      .then((response) => {
        if (response.status === 200) {
          setCookie(response.data.access_token, { path: '/' });
          navigate(redirectUrl);
        }
      })
      .catch((error) => {
        setErrors({
          submit: error.response.data.message || 'connection error',
        });
        if (isMounted()) {
          setLoading(false);
        }
      })
      .finally(() => {
        if (isMounted()) {
          setLoading(false);
        }
      });
  };

  return (
    <div className="form-center">
      <Container>
        <Row className="justify-content-center">
          <Col xs={10} sm={9} md={7} lg={5}>
            <h4>
              <Badge variant="secondary" className="badge">
                {title} Log in{' '}
              </Badge>
            </h4>

            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email Address *"
                  required
                  onChange={handleChange}
                />
                <Form.Text className="error"> {errors.email} </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type={showPassword ? `` : `password`}
                  name="password"
                  required
                  placeholder="Password *"
                  onChange={handleChange}
                />
                <Form.Text className="error"> {errors.password} </Form.Text>
              </Form.Group>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Show password"
                  onChange={() => setShowPassword((showPass) => !showPass)}
                />
              </Form.Group>

              <div style={{ display: 'flex' }}>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={(event) => {
                    if (!loading) {
                      event.preventDefault();
                      handleSubmit();
                    }
                  }}
                  disabled={loading}
                >
                  <ActionSpinner showSpinner={loading} defaultText={'Log in'} />
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>

      <Row>
        <Col>
          <div style={{ padding: '5px' }}>
            {errors.submit && <Alert variant={'danger'}>{errors.submit}</Alert>}
          </div>
        </Col>
      </Row>
    </div>
  );
};
