import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';

// https://github.com/vercel/swr/blob/master/examples/axios-typescript/libs/useRequest.ts

export type GetRequest = AxiosRequestConfig | null;

interface Return<Data, Error>
  extends Pick<
    SWRResponse<AxiosResponse<Data>, AxiosError<Error>>,
    'isValidating' | 'error' | 'mutate'
  > {
  data: Data | undefined;
  response: AxiosResponse<Data> | undefined;
  isLoading: boolean;
  mutateData: (
    callback: ((data: Data) => Data) | Data,
    shouldRevalidate?: boolean,
  ) => Promise<AxiosResponse<Data>>;
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
    SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>,
    'initialData'
  > {
  initialData?: Data;
}

export function useRequest<Data = unknown, Error = unknown>(
  request: GetRequest,
  { initialData, ...config }: Config<Data, Error> = {},
): Return<Data, Error> {
  const {
    data: response,
    error,
    isValidating,
    mutate,
    isLoading,
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    request && JSON.stringify(request),
    /**
     * NOTE: Typescript thinks `request` can be `null` here, but the fetcher
     * function is actually only called by `useSWR` when it isn't.
     */
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => axios(request!),
    {
      ...config,
      fallbackData: initialData && {
        status: 200,
        statusText: 'InitialData',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        config: request!,
        headers: {},
        data: initialData,
      },
    },
  );

  const mutateData = (
    callback: ((data: Data) => Data) | Data,
    shouldRevalidate?: boolean,
  ) =>
    mutate(
      (res) =>
        ({
          ...res,
          data: callback instanceof Function ? callback(res!.data) : res!.data,
        }) as AxiosResponse<Data>,
      shouldRevalidate,
    ) as Promise<AxiosResponse<Data>>;

  return {
    data: response && response.data,
    response: response,
    error: error,
    isValidating: isValidating,
    isLoading,
    mutate: mutate,
    mutateData: mutateData,
  };
}
