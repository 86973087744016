import { Row, TextCell } from '@silevis/reactgrid';
import moment from 'moment';
import { Moment } from 'moment-business-days';
import {
  getExtendedHeaderCell,
  getHeaderCell,
  getTextCell,
} from '../templates/cellGetters';
import { style2 } from './getDaysOfWeekRows';
import { Project } from '../interfaces/Project.interface';
import {
  DeveloperPortalCellTypes,
  RowCell,
} from '../interfaces/reactgrid.interface';
import { nonEditableStatuses } from '../enums/NonEditableStatus.enum';
import { shouldBeLocked } from './shouldBeLocked';
import { UserRole } from '../enums/UserRole.enum';
import { isCollaboratorDeleted } from './isCollaboratorDeleted';

export const getWorkItemRows = (
  project: Project,
  days: Moment[],
  employeeLockDate: string,
  employeeRole: UserRole,
): Row<DeveloperPortalCellTypes>[] => {
  const projectRows: Row<DeveloperPortalCellTypes> = {
    rowId: project.id,
    height: 35,
    cells: [
      {
        type: 'nonEditableChevron',
        text: project.name,
        isExpanded: !project.hidden,
        className: 'style-full-light-green text-bold',
        hasChildren: true,
      },
      getTextCell('', {}, 'style-full-light-green'),
      getTextCell('', {}, 'style-full-light-green'),
      ...days.map((day): TextCell => {
        const styles =
          moment(day).format('DD-MM-YYYY') ===
          moment(new Date()).format('DD-MM-YYYY')
            ? { ...style2 }
            : {};
        return getTextCell('', styles, 'style-full-light-green', true);
      }),
      getTextCell('', {}, 'style-full-light-green'),
    ],
  };

  const workItemRows: Row<RowCell>[] = project.workItems.map((workItem) => {
    const foundWorkItemRoleHour = workItem.workItemRoleHours?.find(
      (item) => item.role?.id === project.collaborators[0].role?.id,
    );
    const isNonEditable = nonEditableStatuses.some(
      (status) => status === workItem.status,
    );
    const shouldLockBecauseCollaboratorDeleted = isCollaboratorDeleted(
      project.collaborators[0],
    );
    const cellClassName =
      isNonEditable || shouldLockBecauseCollaboratorDeleted
        ? 'style-light-gray'
        : 'style-semi-transparent-light-green';

    return {
      rowId: workItem.id,
      height: 30,
      cells: [
        getExtendedHeaderCell(
          workItem.name,
          {
            edit: false,
          },
          { paddingLeft: '25px' },
          cellClassName,
          true,
          shouldLockBecauseCollaboratorDeleted
            ? `Collaborator deleted from ${project.name}`
            : isNonEditable
              ? 'Workitem is archived'
              : null,
          shouldLockBecauseCollaboratorDeleted || isNonEditable
            ? `You cannot modify ${workItem.name}`
            : workItem.name,
        ),
        getExtendedHeaderCell(
          workItem.extId,
          {
            edit: false,
          },
          null,
          cellClassName,
        ),
        getTextCell('', {}, cellClassName),
        ...days.map((day): RowCell => {
          const result = workItem.timetableEntries.find((timeTableEntry) =>
            moment(timeTableEntry.date).isSame(day, 'date'),
          );
          if (isNonEditable || shouldLockBecauseCollaboratorDeleted) {
            const styles =
              moment(day).format('DD-MM-YYYY') ===
              moment(new Date()).format('DD-MM-YYYY')
                ? { ...style2, background: '#dedede' }
                : { background: '#dedede' };
            return getHeaderCell(
              `${result?.hours || ''}`,
              styles,
              'justify-content-end',
            );
          }

          const isTimeLoggingLocked = shouldBeLocked(
            moment(employeeLockDate, 'DD-MM-YYYY').toDate(),
            moment(day).toDate(),
            employeeRole,
          );

          return {
            nonEditable:
              isTimeLoggingLocked || shouldLockBecauseCollaboratorDeleted,
            className:
              isTimeLoggingLocked || shouldLockBecauseCollaboratorDeleted
                ? 'non-editable-cell'
                : '',
            type: 'number',
            value: result?.hours || NaN,
            style:
              moment(day).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
                ? style2
                : {},
            hideZero: true,
            collaboratorId: project.collaborators[0].id,
            workItemRoleHoursId: foundWorkItemRoleHour?.id,
          };
        }),
        getTextCell('', null, cellClassName),
      ],
    };
  });

  return project.hidden ? [projectRows] : [projectRows, ...workItemRows];
};
