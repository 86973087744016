import { AxiosError, AxiosResponse } from 'axios';
import { SWRConfiguration } from 'swr';

export const swrConfig: SWRConfiguration<
  AxiosResponse<unknown>,
  AxiosError<unknown>
> = {
  refreshInterval: 0,
  refreshWhenHidden: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
