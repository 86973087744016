import * as React from 'react';
import {
  Cell,
  getCharFromKeyCode,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  keyCodes,
  isAlphaNumericKey,
  isNavigationKey,
  getCellProperty,
} from '@silevis/reactgrid';
import autosize from 'autosize';

export interface TextAreaCell extends Cell {
  type: 'textArea';
  text: string;
  placeholder?: string;
  timetableEntryId?: string;
  validator?: (text: string) => boolean;
  renderer?: (text: string) => React.ReactNode;
  nonEditable?: boolean;
}

export class TextAreaCellTemplate implements CellTemplate<TextAreaCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<TextAreaCell>,
  ): Compatible<TextAreaCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let placeholder: string | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
      placeholder = '';
    }
    const value = parseFloat(text); // TODO more advanced parsing for all text based cells
    return { ...uncertainCell, text, value, placeholder };
  }

  update(
    cell: Compatible<TextAreaCell>,
    cellToMerge: UncertainCompatible<TextAreaCell>,
  ): Compatible<TextAreaCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder,
    });
  }

  handleKeyDown(
    cell: Compatible<TextAreaCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
  ): { cell: Compatible<TextAreaCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift);
    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE)
    )
      return {
        cell: this.getCompatibleCell({
          ...cell,
          text: shift ? char : char.toLowerCase(),
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode:
        keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  getClassName(cell: Compatible<TextAreaCell>, isInEditMode: boolean): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className ? cell.className : '';
    return `${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' ? 'placeholder' : ''
    } ${className}`;
  }

  render(
    cell: Compatible<TextAreaCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<TextAreaCell>, commit: boolean) => void,
  ): React.ReactNode {
    if (!isInEditMode) {
      const textToDisplay =
        cell.text === '' ? cell.placeholder || '' : cell.text;
      return <div className={'text-overflow-ellipsis'}>{textToDisplay}</div>;
    }
    //When this hideHandler is, there's no append changes to cell
    // const hideHandler = () => {
    //   onCellChanged(this.getCompatibleCell({ ...cell, groupId: 'A' }), true);
    //   document.removeEventListener('pointerdown', hideHandler);

    // }

    return (
      <textarea
        ref={(textarea) => {
          if (textarea) {
            textarea.focus();
            // document.addEventListener('pointerdown', hideHandler);
            textarea.setSelectionRange(
              textarea.value.length,
              textarea.value.length,
            );
            autosize(textarea);
          }
        }}
        style={{
          maxHeight: '200px',
          width: 'calc(100% + 4px)',
          boxSizing: 'border-box',
          padding: '4px',
          overflow: 'hidden',
          border: 'inherit',
          outline: 'none',
          position: 'absolute',
          margin: '-2px -6px',
        }}
        defaultValue={cell.text}
        onChange={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            false,
          )
        }
        onBlur={(e) =>
          onCellChanged(
            this.getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            true,
          )
        }
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={(e) => {
          if (
            isAlphaNumericKey(e.keyCode) ||
            isNavigationKey(e.keyCode) ||
            (e.shiftKey && e.keyCode === keyCodes.ENTER)
          ) {
            e.stopPropagation();
          }
        }}
        disabled={cell.nonEditable}
      />
    );
  }
}
