import React, { FC } from 'react';
import { Col, Container, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppRoutes } from '@portal/frontend/utils';
import { useGlobalConfig } from './GlobalConfigProvider';
import { Logout } from './Logout';
import { NavDropdownTitle } from './NavDropdownTitle';
import styled from 'styled-components';

const StyledNavbar = styled(Navbar)`
  background: #ffffff;
  border-bottom: 1px solid #e9ecf2;
  justify-content: space-between;
  .disable-content-chevron .dropdown-toggle:after {
    content: none;
  }
`;

const StyledNavbarBrand = styled(Navbar.Brand)`
  && {
    color: #007bff;
    font-weight: bold;
    &:hover {
      color: #035bb9;
    }
  }
`;

export const NavigationBar: FC = ({ children }) => {
  const { title } = useGlobalConfig();
  return (
    <StyledNavbar variant="light" className="d-print-none">
      <Container fluid>
        <Col xs={3}>
          <Link to={AppRoutes.HOME}>
            <StyledNavbarBrand>{title}</StyledNavbarBrand>
          </Link>
        </Col>
        <Col>{children}</Col>
        <Col xs="auto">
          <NavDropdown
            className={'disable-content-chevron'}
            alignRight
            title={<NavDropdownTitle />}
            id="current-user-menu-dropdown"
          >
            <NavDropdown.Item as={Link} to={AppRoutes.PROFILE}>
              Change password
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <Logout>
              <NavDropdown.Item>Log out</NavDropdown.Item>
            </Logout>
          </NavDropdown>
        </Col>
      </Container>
    </StyledNavbar>
  );
};
