import React, { FC } from 'react';
import { Badge } from 'react-bootstrap';
import { useWorkingHours } from '../hooks/useWorkingHours';
import { Moment } from 'moment-business-days';
import { useSumEmployeeWorkHours } from '../hooks/useSumEmployeeWorkHours';

interface ReportedHoursBagdeProps {
  employeeId: string;
  firstDay: Moment;
  lastDay: Moment;
}

export const ReportedHoursBagde: FC<ReportedHoursBagdeProps> = ({
  firstDay,
  lastDay,
  employeeId,
}) => {
  const { workingHours, isValidating: isValidatingWorkingHours } =
    useWorkingHours();

  const { sumEmployeeFirstMonthHours, sumEmployeeSecondMonthHours } =
    useSumEmployeeWorkHours(employeeId, firstDay, lastDay);

  const isSameMonth = firstDay.isSame(lastDay, 'month');

  const getWorkingHoursForMonth = (date: Moment) => {
    return (
      workingHours.find(
        (workHour) =>
          `${workHour.year}` === date.format('YYYY') &&
          `${workHour.month}` === date.format('M'),
      )?.hours || '-'
    );
  };

  return (
    !isValidatingWorkingHours &&
    workingHours && (
      <Badge pill variant="light">
        {firstDay.format('MMMM YYYY')} ({sumEmployeeFirstMonthHours}/
        {getWorkingHoursForMonth(firstDay)})
        {!isSameMonth && (
          <>
            {' '}
            / {lastDay.format('MMMM YYYY')} ({sumEmployeeSecondMonthHours}/
            {getWorkingHoursForMonth(lastDay)})
          </>
        )}
      </Badge>
    )
  );
};
