import { useEffect, useState } from 'react';
import Axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useGlobalConfig } from '../components/GlobalConfigProvider';
import { useAccessToken } from './useAccessToken';
import React from 'react';
import { ToastContent } from '../components/ToastContent';
import { AppRoutes } from '@portal/frontend/utils';
import { useNavigate } from 'react-router-dom';
import { HistoryPushCause } from '../enums/HistoryPushCause.enum';

export const useAxios = () => {
  const { apiRoot } = useGlobalConfig();
  const { accessToken } = useAccessToken();
  const [errorInterceptor, setErrorInterceptor] = useState(undefined);
  const navigate = useNavigate();

  Axios.defaults.headers = {
    Accept: 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
  };

  Axios.defaults.baseURL = apiRoot;

  const rejectedHandler = async (error: any) => {
    if (error?.response?.status === 401) {
      navigate(AppRoutes.SIGNIN, { state: HistoryPushCause.UNAUTHORIZED });
    }
    const msg = error?.response?.data?.message || '';
    toast.error(
      <ToastContent
        title={error?.response?.data?.error || 'Internal server error'}
        message={msg}
      />,
      {}
    );
    return Promise.reject(error);
  };

  const fulfilledHandler = (
    response: AxiosResponse<unknown>
  ): AxiosResponse<unknown> => {
    const { method } = response?.config;
    if (method !== 'get') {
      toast.success('Success');
    }
    return response;
  };

  const addErrorInterceptor = () => {
    const errorInterceptor = Axios.interceptors.response.use(
      fulfilledHandler,
      (error) => rejectedHandler(error)
    );
    setErrorInterceptor(errorInterceptor);
  };

  const removeErrorInterceptor = () => {
    Axios.interceptors.request.eject(errorInterceptor);
    setErrorInterceptor(undefined);
  };

  useEffect(() => {
    addErrorInterceptor();
    return () => {
      removeErrorInterceptor();
    };
  }, []);

  return null;
};
