import { EmployeeDto } from '@portal/client-portal-api-model';
import Endpoint from '../enums/apiEndpoints';
import { useRequest } from './useRequest';

export function useMe() {
  const {
    data: me,
    response,
    error,
    isValidating,
    mutate,
    mutateData,
  } = useRequest<EmployeeDto>(
    {
      url: `${Endpoint.EMPLOYEES}/me`,
    },
    {
      revalidateOnFocus: true,
    },
  );

  return {
    me: me,
    meResponse: response,
    error,
    isValidating,
    mutateMeResponse: mutate,
    mutateMe: mutateData,
  };
}
