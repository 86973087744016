import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from "react-cookie";
import { SWRConfig } from 'swr';
import '../src/app/assets/scss/core.scss'
import { GlobalConfigProvider, AxiosProvider, swrConfig, Seo, toastConfig } from '@portal/frontend/react';
import Routing from './app/components/Routing';
import { AccessToken } from '@portal/frontend/utils';
import { ToastContainer } from 'react-toastify';

const TITLE = `Developer Portal`;

const App = () => {
  return (
    <GlobalConfigProvider
      accessTokenKeyName={AccessToken.DEVELOPER}
      title={TITLE}
    >
      <Seo title={TITLE} />
      <CookiesProvider>
        <BrowserRouter>
          <AxiosProvider />
          <SWRConfig value={swrConfig}>
            <Routing />
            <ToastContainer {...toastConfig} />
          </SWRConfig>
        </BrowserRouter>
      </CookiesProvider>
    </GlobalConfigProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'));
