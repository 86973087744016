export const getFirstDayOfWeek = (date: Date): Date => {
  const dayOfWeek = date.getDay() || 7;
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - dayOfWeek + 1,
  );
};

export const getLastDayOfWeek = (date: Date): Date => {
  const firstDayOfWeek = getFirstDayOfWeek(date);
  return new Date(
    firstDayOfWeek.getFullYear(),
    firstDayOfWeek.getMonth(),
    firstDayOfWeek.getDate() + 6,
  );
};

export const getFirstDayOfMonth = (today: Date): Date => {
  return new Date(today.getFullYear(), today.getMonth(), 1);
};

export const getLastDayOfMonth = (today: Date): Date => {
  return new Date(today.getFullYear(), today.getMonth() + 1, 0);
};

export const getFirstDayOfYear = (today: Date): Date => {
  return new Date(today.getFullYear(), 0, 1);
};

export const getLastDayOfYear = (today: Date): Date => {
  return new Date(today.getFullYear() + 1, 0, 0);
};
