import moment from 'moment';
import { UserRole } from '../enums/UserRole.enum';

export const shouldBeLocked = (
  lockDate: Date,
  date: Date,
  employeeRole: number,
): boolean => {
  if (employeeRole !== UserRole.EMPLOYEE) {
    return false;
  }

  return !moment(date, 'YYYY-MM-DD').isAfter(moment(lockDate), 'day');
};
