import React, { FC } from 'react';

interface ToggleSwitchProps {
  name: string;
  checked: boolean;
  onChange?: (boolean) => void;
  disabled?: boolean;
}

export const ToggleSwitch: FC<ToggleSwitchProps> = (props) => {
  const { name, checked, onChange, disabled = false } = props;
  return (
    <div className="custom-control custom-switch pr-3">
      <input
        type="checkbox"
        className="custom-control-input"
        id={name}
        readOnly
        disabled={disabled}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <label className="custom-control-label" htmlFor={name}>
        {name}
      </label>
    </div>
  );
};

export default ToggleSwitch;
