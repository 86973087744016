import { EmployeeDto } from '@portal/client-portal-api-model';
import { AccessToken } from '@portal/frontend/utils';
import React, { createContext, FC, useContext, useReducer } from 'react';

interface Config {
  apiRoot: string;
  accessTokenKeyName: AccessToken | undefined;
  currentEmployee: EmployeeDto | undefined;
  title: string;
}

const GlobalContext = createContext(null);

type Action = {
  type: 'UPDATE_EMPLOYEE';
  payload: EmployeeDto;
};

const reducer: (state: Config, action: Action) => Config = (state, action) => {
  switch (action.type) {
    case 'UPDATE_EMPLOYEE': {
      return {
        ...state,
        currentEmployee: action.payload,
      };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
};

export const useGlobalConfig = (): Config & {
  dispatch: React.Dispatch<Action>;
} => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error(
      `useGlobalConfig must be used within a GlobalConfigProvider`
    );
  }
  const [state, dispatch] = context;
  return {
    ...state,
    dispatch,
  };
};

export const GlobalConfigProvider: FC<Partial<Config>> = ({
  children,
  accessTokenKeyName,
  title,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    accessTokenKeyName,
    apiRoot: process.env.NX_API_ENDPOINT,
    currentEmployee: undefined,
    title,
  });
  const value = React.useMemo(() => [state, dispatch], [state]);
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
