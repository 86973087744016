import React from 'react';

export const TableHeader = ({ showHours, showCosts, noBreakdown }) => {
  return (
    <thead style={{ backgroundColor: '#F7F7F7' }}>
      <tr>
        {!noBreakdown && <th style={{ fontWeight: 'normal' }}>Date</th>}
        <th style={{ fontWeight: 'normal' }}>Workitem / Name</th>
        {!noBreakdown && <th style={{ fontWeight: 'normal' }}>Outcome</th>}
        {showHours && <th style={{ fontWeight: 'normal' }}>Hours</th>}
        {showCosts && <th style={{ fontWeight: 'normal' }}>Costs</th>}
      </tr>
    </thead>
  );
};
