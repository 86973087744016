import moment from 'moment';
import { Moment } from 'moment-business-days';
import { useSearchParams } from 'react-router-dom';
import { useDaysOfWeek } from './useDaysOfWeek';
import { getDaysCurrentWeek } from '../functions/getDaysCurrentWeek';
import { getDaysOfWeekRows } from '../functions/getDaysOfWeekRows';
import { useCallback, useEffect } from 'react';

export function useTimesheetDate() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [daysOfWeek, setDaysOfWeek] = useDaysOfWeek();

  const setRangeDate = (startDate: Moment, endDate: Moment) => {
    searchParams.set('startDate', startDate.format('YYYY-MM-DD'));
    searchParams.set('endDate', endDate.format('YYYY-MM-DD'));
    setSearchParams(searchParams, { replace: true });
  };

  const isStartDateValue = moment(
    searchParams.get('startDate'),
    'YYYY-MM-DD',
    true,
  ).isValid();

  const isEndDateValue = moment(
    searchParams.get('endDate'),
    'YYYY-MM-DD',
    true,
  ).isValid();

  const isStartDateKey = searchParams.has('startDate');
  const isEndDateKey = searchParams.has('endDate');

  useEffect(() => {
    if (
      !isStartDateKey ||
      !isEndDateKey ||
      !isStartDateValue ||
      !isEndDateValue
    ) {
      searchParams.set('startDate', daysOfWeek[0].format('YYYY-MM-DD'));
      searchParams.set(
        'endDate',
        daysOfWeek[daysOfWeek.length - 1].format('YYYY-MM-DD'),
      );
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  const startDate = isStartDateValue
    ? moment(searchParams.get('startDate'), 'YYYY-MM-DD')
    : daysOfWeek[0];

  const endDate = isEndDateValue
    ? moment(searchParams.get('endDate'), 'YYYY-MM-DD')
    : daysOfWeek[daysOfWeek.length - 1];

  const formattedStartDate = startDate.format('YYYY-MM-DD').toString();
  const formattedEndDate = endDate.format('YYYY-MM-DD').toString();

  const setPrevMonth = useCallback(() => {
    const firstDayOfPrevMonth = endDate.subtract(1, 'months').startOf('month');
    const daysInPrevMonth = getDaysCurrentWeek(firstDayOfPrevMonth);

    setRangeDate(
      firstDayOfPrevMonth,
      daysInPrevMonth[daysInPrevMonth.length - 1],
    );

    setDaysOfWeek(daysInPrevMonth);
  }, [endDate, setRangeDate, setDaysOfWeek]);

  const setNextMonth = useCallback(() => {
    const firstDayOfNextMonth = endDate.add(1, 'months').startOf('month');
    const daysInNextMonth = getDaysCurrentWeek(firstDayOfNextMonth);

    setRangeDate(
      firstDayOfNextMonth,
      daysInNextMonth[daysInNextMonth.length - 1],
    );

    setDaysOfWeek(daysInNextMonth);
  }, [endDate, setRangeDate, setDaysOfWeek]);

  const setPreviousWeek = useCallback(() => {
    const firstDayOfPrevWeek = endDate.subtract(1, 'weeks').startOf('week');
    const daysInPrevWeek = getDaysCurrentWeek(firstDayOfPrevWeek);

    setRangeDate(firstDayOfPrevWeek, daysInPrevWeek[daysInPrevWeek.length - 1]);

    setDaysOfWeek(daysInPrevWeek);
  }, [endDate, setRangeDate, setDaysOfWeek]);

  const setNextWeek = useCallback(() => {
    const firstDayOfNextWeek = endDate.add(1, 'weeks').startOf('week');
    const daysInNextWeek = getDaysCurrentWeek(firstDayOfNextWeek);

    setRangeDate(firstDayOfNextWeek, daysInNextWeek[daysInNextWeek.length - 1]);

    setDaysOfWeek(daysInNextWeek);
  }, [endDate, setRangeDate, setDaysOfWeek]);

  const setCurrentWeek = useCallback(() => {
    const daysInCurrentWeek = getDaysCurrentWeek(moment());

    setRangeDate(
      daysInCurrentWeek[0],
      daysInCurrentWeek[daysInCurrentWeek.length - 1],
    );

    setDaysOfWeek(daysInCurrentWeek);
  }, [setRangeDate, setDaysOfWeek]);

  const daysRows = getDaysOfWeekRows(daysOfWeek, setPreviousWeek, setNextWeek);

  return {
    startDate,
    endDate,
    formattedStartDate,
    formattedEndDate,
    daysRows,
    daysOfWeek,
    setDaysOfWeek,
    setRangeDate,
    setPrevMonth,
    setNextMonth,
    setPreviousWeek,
    setNextWeek,
    setCurrentWeek,
  };
}
