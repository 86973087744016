import { DailyWorkTimespan } from '@portal/database-entities';
import { useRequest } from './useRequest';
import Endpoint from '../enums/apiEndpoints';

export function useDailyWorkTimespans(employeeId: string) {
  const url = employeeId
    ? `${Endpoint.DAILY_WORK_TIMESPANS}/${employeeId}`
    : null;

  const {
    data: workTimespans,
    response,
    error,
    isValidating,
    mutate,
    mutateData,
  } = useRequest<DailyWorkTimespan[]>({
    url,
  });

  return {
    workTimespans,
    workTimespansResponse: response,
    error,
    isValidating,
    mutateWorkTimespansResponse: mutate,
    mutateWorkTimespans: mutateData,
  };
}
