import React, { useEffect } from 'react';
import {
  TimeSheetTable,
  useAccessToken,
  useTimesheetDate,
  WorkItemsDescriptionTable,
} from '@portal/frontend/react';
import { useSearchParams } from 'react-router-dom';

export const DevPortalWrapper = () => {
  const {
    decodedToken: { sub: employeeId, role },
  } = useAccessToken();

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    startDate,
    endDate,
    daysOfWeek,
    daysRows,
    setRangeDate,
    setPrevMonth,
    setNextMonth,
    setCurrentWeek,
  } = useTimesheetDate();

  useEffect(() => {
    if (!searchParams.has('showRecentTasks')) {
      searchParams.set('showRecentTasks', 'true');
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  return (
    <>
      <div className="d-flex justify-content-center mb-5">
        <TimeSheetTable
          employeeId={employeeId}
          daysOfWeek={daysOfWeek}
          startDate={startDate}
          endDate={endDate}
          daysRows={daysRows}
          setPrevMonth={setPrevMonth}
          setNextMonth={setNextMonth}
          setRangeDate={setRangeDate}
          setCurrentWeek={setCurrentWeek}
          authenticatedUserRole={role}
        />
      </div>

      <div className="d-flex justify-content-center">
        <WorkItemsDescriptionTable
          employeeId={employeeId}
          daysOfWeek={daysOfWeek}
          authenticatedUserRole={role}
        />
      </div>
    </>
  );
};
