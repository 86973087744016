import { useMemo } from 'react';
import { Moment } from 'moment-business-days';
import { useRequest } from './useRequest';
import Endpoint from '../enums/apiEndpoints';
import { EmployeeWorkHours } from '../interfaces/EmployeeWorkHours.interface';

export function useSumEmployeeWorkHours(
  employeeId: string,
  firstDay: Moment,
  lastDay: Moment,
) {
  const firstMonth = firstDay.format('YYYY-MM');
  const lastMonth = lastDay.format('YYYY-MM');

  const fetchDataFor = useMemo(
    () => (month: string) => {
      return {
        url: `${Endpoint.EMPLOYEE_WORK_HOURS}/${employeeId}`,
        params: { date: month },
      };
    },
    [employeeId],
  );

  const requestFirstMonth = fetchDataFor(firstMonth);
  const requestSecondMonth = fetchDataFor(lastMonth);

  const { mutate: mutateFirsMonth, data: firstMonthData } =
    useRequest<EmployeeWorkHours>(requestFirstMonth, {
      keepPreviousData: true,
    });

  const { mutate: mutateSecondMonth, data: secondMonthData } =
    useRequest<EmployeeWorkHours>(requestSecondMonth, {
      keepPreviousData: true,
    });

  return {
    sumEmployeeFirstMonthHours: firstMonthData?.sumEmployeeMonthHours,
    mutateReportedHoursFirstMonth: mutateFirsMonth,
    sumEmployeeSecondMonthHours: secondMonthData?.sumEmployeeMonthHours,
    mutateReportedHoursSecondMonth: mutateSecondMonth,
  };
}
