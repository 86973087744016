import moment from 'moment';
import { Moment } from 'moment-business-days';
import Endpoint from '../enums/apiEndpoints';
import { TotalWorkHour } from '../interfaces/TotalWorkHour.interface';
import { useRequest } from './useRequest';

export function useTotalWorkHours(
  employeeId: string,
  startDate: Moment,
  endDate: Moment,
) {
  const {
    data: totalWorkHours,
    error,
    isValidating: isValidatingTotalWorkHours,
    mutateData,
  } = useRequest<TotalWorkHour[]>({
    url: `${Endpoint.TOTAL_WORK_HOURS}/${employeeId}`,
    params: {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    },
  });

  return {
    totalWorkHours,
    error,
    isValidatingTotalWorkHours,
    mutateTotalWorkHours: mutateData,
  };
}
