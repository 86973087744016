import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title: string;
}

export const Seo: FC<SeoProps> = ({ children, title }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <base href="/" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" type="image/x-icon" href="favicon.ico" />
      {children}
    </Helmet>
  );
};
