import React from 'react';
import {
  CellTemplate,
  Uncertain,
  Compatible,
  Cell,
  UncertainCompatible,
} from '@silevis/reactgrid';
import { Button } from 'react-bootstrap';
import { ButtonProps } from 'react-bootstrap';

export interface ButtonCell extends Cell {
  type: 'button';
  text: string;
  context?: string;
  variant?: ButtonProps['variant'];
  disabled?: boolean;
}

export class ButtonCellTemplate implements CellTemplate<ButtonCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<ButtonCell>
  ): Compatible<ButtonCell> {
    return {
      ...uncertainCell,
      text: uncertainCell.text || '',
      className: uncertainCell.className,
      value: NaN,
    };
  }

  update(
    cell: Compatible<ButtonCell>,
    cellToMerge: UncertainCompatible<ButtonCell>
  ): Compatible<ButtonCell> {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
  }

  render(
    cell: Compatible<ButtonCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<ButtonCell>, commit: boolean) => void
  ): React.ReactNode {
    return (
      <div className="d-flex justify-content-center w-100">
        <Button
          variant={cell.variant || 'danger'}
          disabled={cell.disabled}
          size="sm"
          onClick={(e) =>
            onCellChanged(
              this.getCompatibleCell({ ...cell, text: 'clicked' }),
              true
            )
          }
        >
          {cell.text}
        </Button>
      </div>
    );
  }
}
