import * as React from 'react';
import {
  CellTemplate,
  Uncertain,
  Compatible,
  Cell,
  CellStyle,
} from '@silevis/reactgrid';
import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
export interface ChangeWeekCell extends Cell {
  type: 'changeWeek';
  onClick?: () => void;
  nextWeek?: boolean;
}

export class ChangeWeekCellTemplate implements CellTemplate<ChangeWeekCell> {
  isFocusable = () => false;

  getCompatibleCell(
    uncertainCell: Uncertain<ChangeWeekCell>
  ): Compatible<ChangeWeekCell> {
    return { ...uncertainCell, text: '', value: 0 };
  }

  render(
    cell: Compatible<ChangeWeekCell>,
    isInEditMode: boolean
  ): React.ReactNode {
    return (
      <Button
        onClick={cell.onClick}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          fontWeight: 'bold',
        }}
        onPointerDown={(e) => {
          e.stopPropagation();
        }}
        onPointerMove={(e) => {
          e.stopPropagation();
        }}
      >
        {cell.nextWeek ? <ChevronLeft /> : <ChevronRight />}
      </Button>
    );
  }

  getStyle = (): CellStyle => ({ background: '#F2F2F2' });
}
