import * as React from 'react';
import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
  getCellProperty,
} from '@silevis/reactgrid';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';

export interface ExtendedHeaderCell extends Cell, Span {
  type: 'extendedHeader';
  text: string;
  meta: {
    edit: boolean;
    projectName?: string;
    projectId?: string;
    workItemId?: string;
    workHourId?: string;
  };
  enableTooltip?: boolean;
  tooltipHeader?: string;
  tooltipText?: string;
}

export class ExtendedHeaderCellTemplate
  implements CellTemplate<ExtendedHeaderCell>
{
  openModal: (cell: ExtendedHeaderCell) => void | undefined;

  constructor(props?: { openModal?: (cell: ExtendedHeaderCell) => void }) {
    if (props?.openModal) {
      this.openModal = props.openModal;
    }
  }

  handleClick(cell: ExtendedHeaderCell): void {
    this.openModal(cell);
  }

  getCompatibleCell(
    uncertainCell: Uncertain<ExtendedHeaderCell>,
  ): Compatible<ExtendedHeaderCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');

    let enableTooltip = false;

    const { meta } = uncertainCell;

    try {
      enableTooltip = getCellProperty(
        uncertainCell,
        'enableTooltip',
        'boolean',
      );
    } catch (error) {}
    const value = parseFloat(text);
    return {
      ...uncertainCell,
      text,
      meta,
      value,
      enableTooltip,
    };
  }

  render(
    cell: Compatible<ExtendedHeaderCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<ExtendedHeaderCell>,
      commit: boolean,
    ) => void,
  ) {
    return (
      <>
        <OverlayTrigger
          placement={'top-start'}
          delay={{
            hide: 100,
            show: 500,
          }}
          overlay={
            <Tooltip id={`tooltip-top`}>
              {cell.tooltipHeader && <p>{cell.tooltipHeader}</p>}

              {cell.tooltipText ? cell.tooltipText : cell.text}
            </Tooltip>
          }
        >
          <span className={'text-overflow-ellipsis'}>{cell.text}</span>
        </OverlayTrigger>
        {cell.meta.edit && (
          <Pencil
            style={{
              position: 'absolute',
              width: 20,
              height: 20,
              right: 2,
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
            }}
            onClick={() => this.handleClick(cell)}
          />
        )}
      </>
    );
  }

  isFocusable = (cell: Compatible<ExtendedHeaderCell>): boolean => false;

  getClassName(cell: Compatible<ExtendedHeaderCell>): string {
    return cell.className ? cell.className : '';
  }

  getStyle = (cell: Compatible<ExtendedHeaderCell>): CellStyle => ({
    background: 'rgba(0, 0, 0, 0.05)',
  });
}
