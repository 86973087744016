import { Moment } from 'moment';
import moment from 'moment/moment';

export const getDaysFromSearchParams = (date: Moment): Moment[] => {
  const days = [];
  for (let i = 0; i <= 6; i++) {
    days.push(moment(date).add(i, 'days'));
  }
  return days;
};
