import React, { FC } from 'react';
import { UserRole } from '../enums/UserRole.enum';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { Loader } from './Loader';

interface RoleGuardProps {
  allowedRoles: UserRole[];
}

export const ViewGuard: FC<RoleGuardProps> = ({ children, allowedRoles }) => {
  const employee = useCurrentUser();
  if (!employee) {
    return <Loader fullScreen={true} />;
  }
  return (
    <>
      {!allowedRoles.includes(employee.role) ? (
        <h1> This view is forbidden! </h1>
      ) : (
        children
      )}
    </>
  );
};
