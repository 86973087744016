import { useRequest } from './useRequest';
import { MonthlyWorkingHours } from '@portal/database-entities';
import Endpoint from '../enums/apiEndpoints';

export function useWorkingHours() {
  const {
    data: workingHours,
    response,
    error,
    isValidating,
    mutate,
    mutateData,
  } = useRequest<MonthlyWorkingHours[]>({
    url: `${Endpoint.MONTHLY_WORKING_HOURS}`,
  });

  return {
    workingHours: workingHours,
    workingHoursResponse: response,
    error,
    isValidating,
    mutateWorkingHoursResponse: mutate,
    mutateWorkingHours: mutateData,
  };
}
