import { Column } from '@silevis/reactgrid';
import {
  FIRST_LEFT_COL_WIDTH,
  IDS_COL_WIDTH,
  CHEVRON_COL_WIDTH,
} from '../utils/consts';

export const getTimeSheetTableColumns = (
  daysOfWeek: moment.Moment[],
): Column[] => [
  { columnId: 'projects-tree', width: FIRST_LEFT_COL_WIDTH },
  { columnId: 'id-feature', width: IDS_COL_WIDTH },
  { columnId: 'chevron-left', width: CHEVRON_COL_WIDTH },
  ...daysOfWeek.map((day) => ({
    columnId: day.clone().utc(true).toISOString(),
    width: 60,
  })),
  { columnId: 'chevron-right', width: CHEVRON_COL_WIDTH },
];
