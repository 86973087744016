import React from 'react';
import { FC } from 'react';
import LO from 'react-loading-overlay';

interface LoadingOverlayProps {
  show: boolean;
  text?: string;
}

export const LoadingOverlay: FC<LoadingOverlayProps> = ({
  show,
  text = ``,
  children,
}) => {
  return (
    <LO active={show} spinner text={text}>
      {children}
    </LO>
  );
};
