import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  LoginView,
  NotFound,
  PrivateRoute,
  ProfileView,
} from '@portal/frontend/react';
import { TimeSheetView } from '../views/TimeSheetView';
import { AppRoutes } from '@portal/frontend/utils';
import ReportsView from '../views/Reports.view';
import { DevPortalWrapper } from './DevPortalWrapper';

const Routing = () => {
  return (
    <Routes>
      <Route path={AppRoutes.SIGNIN} element={<LoginView />} />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <TimeSheetView />
          </PrivateRoute>
        }
      >
        <Route
          path={AppRoutes.DEVELOPER_PORTAL}
          element={<DevPortalWrapper />}
        />

        <Route
          path={AppRoutes.HOME}
          element={<Navigate to={AppRoutes.DEVELOPER_PORTAL} />}
        />

        <Route path={AppRoutes.REPORT} element={<ReportsView />} />

        <Route path={AppRoutes.PROFILE} element={<ProfileView />} />
      </Route>
      <Route path="*" Component={NotFound} />
    </Routes>
  );
};

export default Routing;
